import type { Config } from 'next-i18n-router/dist/types'

import { BRAND_CONFIG } from './src/brands/brand-configurations'
import { CookieNames } from './src/helpers/cookie'

const YEAR_IN_SECONDS = 60 * 60 * 24 * 365

export const i18nConfig: Config = {
  locales: BRAND_CONFIG.allowedLanguages,
  defaultLocale: BRAND_CONFIG.defaultLanguage,
  prefixDefault: true,
  localeCookie: CookieNames.SELECTED_LANGUAGE,
  cookieOptions: {
    maxAge: YEAR_IN_SECONDS,
    /**
     * We want to include the cookie in cross-site requests.
     * Otherwise we lose the cookie e.g. during Schibsted login.
     */
    sameSite: 'lax',
  },
}
